import { BREAKPOINTS } from '../config';
import MaterialDesignComponentHandler from '../modules/materialDesignComponents';

type ObjectEntriesTuple = [string, any];

export default class Utils {

    public static isPositiveInteger = (str: string, strict: boolean) => {
        if (typeof str !== 'string') {
            return false;
        } else {
            const num = Number(str);
            return Number.isInteger(num) && (strict ? (num > 0) : (num >= 0));
        }
    }

    public static truncateText(str: string, length: number = 15, ending: string = '...') {
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    }

    public static fixSelectForSizeLarge = (element: HTMLElement): void => {
        element.querySelectorAll('select[data-size]').forEach((select: HTMLElement) => {
            if (BREAKPOINTS.minMedium()) {
                const label = select.parentNode.querySelector('.mdc-floating-label');

                if (label) {
                    const floatingLabel = MaterialDesignComponentHandler.getMDCFloatingLabel(label as HTMLElement);
                    const notch = label.closest('.mdc-notched-outline');
                    const notchedOutline = MaterialDesignComponentHandler.getMDCNotchedOutline(notch as HTMLElement);

                    if (floatingLabel && notchedOutline) {
                        floatingLabel.float(true);
                        setTimeout(() => {
                            notchedOutline.notch(floatingLabel.getWidth() * 0.75);
                        }, 10);
                    }

                    const selectOptionElement: HTMLOptionElement = select.querySelector('option');

                    if (selectOptionElement) {
                        selectOptionElement.setAttribute('selected', 'false');
                        const optionEmptyValueElement: HTMLOptionElement = select.querySelector('option[value=""]');
                        if (optionEmptyValueElement) {
                            optionEmptyValueElement.parentElement.removeChild(optionEmptyValueElement);
                        }
                        selectOptionElement.removeAttribute('selected');
                    }
                }
            } else {
                select.removeAttribute('size');
            }
        });
    }

    public static getDeviceInfo = () => {
        let calcResizeTimeout = null;

        const calcResizeHandler = () => {
            window.clearTimeout(calcResizeTimeout);
            calcResizeTimeout = window.setTimeout(Utils.calcDeviceInfo, 100);
        };

        if ('addEventListener' in window) {
            window.addEventListener('resize', calcResizeHandler, false);
        }

        Utils.calcDeviceInfo();
    }

    public static getURLAnchor = (): string => window.location.hash.substr(1);

    public static getURLParamValue = (paramToGet: string): string | void => {
        let value;
        window.location.search.substr(1).split('&').forEach((param: string) => {
            const paramParts = param.split('=');
            if (paramParts[0] === paramToGet) {
                value = paramParts[1];
                return;
            }
        });
        return value;
    }

    public static getURLParam = (paramToGet: string): string => Object.keys(paramToGet).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(paramToGet[key])).join('&');

    public static getObjectEntries = <T>(obj: T): ObjectEntriesTuple[] => Object.entries ? Object.entries(obj) : Object.keys(obj).map(key => [key, obj[key]]);

    public static getBodyId = (): string => document.querySelector('body').getAttribute('id') || '';

    public static calcDeviceInfo = (): void => {
        const height = window.innerHeight || document.documentElement.clientHeight;
        const width  = window.innerWidth  || document.documentElement.clientWidth;

        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${height}px`);

        if (document.querySelector('#device-info-container')) {
            document.querySelector('#device-info-width').innerHTML = width + 'px';
            document.querySelector('#device-info-height').innerHTML = height + 'px';
        }
    }

    /**
     * example:
     * selectorString: 'foo,bar,foobar'
     * returns: '.foo,.bar,.foobar'
     */
    // disable eslint for more readability
    /* eslint-disable arrow-body-style  */
    public static mapClassListToSelector = (selectorString: string): string => {
        return selectorString.split(',').map((el: string) => {
            return `.${el}`;
        }).join(',');
    }
    /* eslint-enable arrow-body-style */

    public static debounce = (func: () => void, wait: number, immediate?: boolean): () => void => {
        let timeout;

        return function() {
            /* eslint-disable */
            const context = this;
            /* eslint-enable */
            const args = arguments;

            const later = () => {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };

            const callNow = immediate && !timeout;

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if (callNow) {
                func.apply(context, args);
            }
        };
    }

    public static stringReplace = (originalString: string, replaces: string[]): string => {
        let result = originalString;

        replaces.forEach((replacement: string) => {
            result = result.replace('%REPLACE%', replacement);
        });

        return result;
    }

    public static reactReplaceIgnoreCase = (source: string, searchTerm: string, mapper: (match: string, index: number) => React.ReactNode): React.ReactNode[] => {
        const composedSearchTerm: string = searchTerm.split(' ')
            .filter(searchTermPart => searchTermPart !== '')
            .map(searchTermPart => Utils.escapeRegExpSymbols(searchTermPart))
            .join('|');

        const composedSearchTermRegex: RegExp = new RegExp(`(${composedSearchTerm})`, 'i');
        const split: string[] = source.split(composedSearchTermRegex);
        const result: React.ReactNode[] = [];

        for (let i: number = 0; i < split.length; i++) {
            if (composedSearchTermRegex.test(split[i])) {
                result.push(mapper(split[i], i));
            } else {
                result.push(split[i]);
            }
        }
        return result;
    }

    public static nl2br = (str: string, is_xhtml?: boolean): string => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    public static getDimensions = (element: HTMLElement): number => {
        const elementHeight: string = document.defaultView.getComputedStyle(element, '').getPropertyValue('height');
        const elementMargin: string = parseInt(document.defaultView.getComputedStyle(element, '').getPropertyValue('margin-top'), 10) + parseInt(document.defaultView.getComputedStyle(element, '').getPropertyValue('margin-bottom'), 10) + 'px';

        return parseInt(elementHeight + elementMargin, 10);
    }

    private static escapeRegExpSymbols = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

    public static generateStringSlug = (string): string => {
        let str = string.toLocaleLowerCase();

        const searchString = ['ä', 'ö', 'ü', 'ß', '%', 'î', 'ç', '°', '@', ' '];
        const replaceString = ['ae', 'oe', 'ue', 'ss', 'prozent', 'i', 'c', 'o', 'at', '-'];


        for (let i = 0; i < str.length; i++) {
            str = str.split(searchString[i]).join(replaceString[i])
        }

        return str;
    }

    public static getRandomId = (): string => {
        return Math.random().toString(16).slice(2);
    }

    public static formatPrice(price: Number)
    {
        return (price / 100).toFixed(2);
    }
}
