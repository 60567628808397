import {
    PARAM_BIKE_CODE, PARAM_CATEGORY_KEY,
    PARAM_PLACEMENT, PARAM_PROMOTION_CODE, PARAM_SEARCH_TERM, PARAM_SKU
} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import {IApiQueryData} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';

export default class ETrackerHelper {
    public static getDefaultListType(): string {
        return 'productcatalog'
    }
    public static getBrandType(): string {
        const brand = window.location.pathname.split("/").filter(Boolean).pop();
        return `brand|${brand}`;
    }

    public static getSearchType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_SEARCH_TERM] ? `searchResult|${defaultQueryObject[PARAM_SEARCH_TERM]}` : this.getDefaultListType();
    }

    public static getBikeDetailType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_BIKE_CODE] ? `bikeDetail|${defaultQueryObject[PARAM_BIKE_CODE][0]}` : this.getDefaultListType();
    }

    public static getPromotionType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_PROMOTION_CODE] ? `promotion|${defaultQueryObject[PARAM_PROMOTION_CODE]}` : this.getDefaultListType();
    }

    public static getPromotionSliderType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_PROMOTION_CODE] ? `promotionProductSlider|${defaultQueryObject[PARAM_PROMOTION_CODE]}` : this.getDefaultListType();
    }

    public static getRecommendationSliderType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_PLACEMENT] ? `recommendationSlider|${defaultQueryObject[PARAM_PLACEMENT]}` : this.getDefaultListType();
    }

    public static getCompatibleProductSliderType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_SKU] ? `compatibleProductSlider|${defaultQueryObject[PARAM_SKU]}` : this.getDefaultListType();
    }

    public static getMainRubricSliderType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_PLACEMENT] ? `mainRubricSlider|${defaultQueryObject[PARAM_PLACEMENT]}` : this.getDefaultListType();
    }

    public static getCategoryType(defaultQueryObject: IApiQueryData): string {
        return defaultQueryObject[PARAM_CATEGORY_KEY] ? `category|${defaultQueryObject[PARAM_CATEGORY_KEY]}` : this.getDefaultListType();
    }
}
