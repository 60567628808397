import { bootstrap } from 'ShopUi/app';

import BikeDetailHandler from './scripts/modules/bikeDetail';
import { FirstPageFootnoteHandler } from './scripts/modules/footnote';
import LexiconMaterialHandler from './scripts/modules/lexiconMaterial';
import MaterialDesignComponentHandler from './scripts/modules/materialDesignComponents';

import EventBus from './scripts/utils/eventBus';
import Utils from './scripts/utils/utils';
import Helper from './scripts/utils/helper';
import './scripts/utils/polyfill';

import CmsReadMoreHandler from './scripts/modules/cmsReadMore';
import AccordionHandler from './scripts/modules/accordion';
import TabsAccordionContainerHandler from './scripts/modules/tabsAccordionContainer';
import AnchorScrollingHandler from './scripts/modules/anchorScrolling';
import DataPasteHandler from './scripts/modules/dataPaste';
import DataSwitchHandler from './scripts/modules/dataSwitch';
import DialogHandler from './scripts/modules/dialog';
import FormValidatorHandler from './scripts/modules/formValidator';
import FormFieldFocusNextBehaviourHandler from './scripts/modules/formFieldFocusNextBehaviour';
import HeaderHandler from './scripts/modules/header';
import LanguageHandler from './scripts/modules/language';
import PasswordInputHandler from './scripts/modules/passwordInputHandler';
import PrintHandler from './scripts/modules/print';
import RadioButtonOptionHandler from './scripts/modules/radioButtonOption';
import ScrollUtils from './scripts/utils/scrollUtils';
import TooltipHandler from './scripts/modules/tooltip';
import TrustedShopsHandler from './scripts/modules/trustedShops';
import ListAccordionHandler from './scripts/modules/listAccordion';
import ArticleFlyToCartHandler from './scripts/modules/articleFlyToCart';
import ReadMoreHandler from './scripts/modules/readMore';
import MiddlewareEventBus from './scripts/react/common/lib/MiddlewareEventBus';
import CmsSliderHandler from './scripts/modules/cmsSlider';
import HttpApi from './scripts/modules/httpApi';
import VideoHandler from './scripts/modules/video';
import AudioHandler from './scripts/modules/audio';
import CmsBenefitsHandler from './scripts/modules/cmsBenefits';
import CmsSocialSharingHandler from './scripts/modules/cmsSocialSharing';
import BrandVideoHandler from './scripts/modules/articleList/brandVideoHandler';
import FormSubmitButtonHandler from './scripts/modules/formSubmitButton';
import CmsStoreMapHandler from './scripts/modules/cmsStoreMap';
import MoveToCartDialogHandler from './scripts/modules/wishList/moveToCartDialog';
import CmsStoreSpecialOpeningAccordionHandler from './scripts/modules/cmsStoreSpecialOpeningAccordion';
import ResponsiveNavigationHandler from './scripts/modules/navigation/responsiveNavigation';
import CatalogPageHandler from './scripts/catalog-page/CatalogPageHandler';
import MyBikePageHandler from './scripts/mybike-page/MyBikePageHandler';
import MainRubricHandler from './scripts/main-rubric-page/MainRubricHandler';
import ProductSliderHandler from './scripts/product-slider/ProductSliderHandler';
import CmsTestimonialsHandler from './scripts/modules/cmsTestimonials';
import { ClickTrackingService } from './scripts/shop-ui/services/ClickTrackingService';
import CmsStoreSearchHandler from './scripts/modules/cmsStoreSearch';
import { setupGtm } from './scripts/shop-ui/services/GoogleTagManagerService';
import FootnoteFirstPageHandler from './scripts/footnote/FootnoteFirstPageHandler';
import LayoutSkeletonHandler from './scripts/modules/layoutSkeleton';
import CmsStoreLocationDropdownHandler from './scripts/modules/cmsStoreLocationDropdown';
import CatalogSearchPageHandler from './scripts/catalog-search-page/CatalogSearchPageHandler';
import MyLouisOrdersHandler from './scripts/mylouis-orders/MyLouisOrdersHandler';
import SearchSuggestionHandler from './scripts/search-suggestion/SearchSuggestionHandler';
import RecommendedDomainHandler from './scripts/recommended-domain/RecommendDomainHandler';
import YoutubeIframeApiIntegrationHandler from "./scripts/modules/articleDetail/youtube-iframe-api-integration";
import ProductWishListHandler from 'PyzShopUi/scripts/product-wish-list/ProductWishListHandler';
import ToastHandler from 'PyzShopUi/scripts/modules/toast';
import CommonEvents from 'PyzShopUi/scripts/modules/events/commonEvents';
import UrlUtils from 'PyzShopUi/scripts/utils/urlUtils';
import CmsYoutube from './scripts/modules/cmsYoutube';
import { initClientSideSentry } from './scripts/shop-ui/services/ClientSideSentryService';
import ShippingAddressSelectHandler from "PyzShopUi/scripts/check-out/ShippingAddressSelect";
import DefaultShippingAddressSelectHandler from 'PyzShopUi/scripts/mylouis-shipping-addresses/DefaultShippingAddress';
import CmsVideoWithText from './scripts/modules/cmsVideoWithText';
import './scripts/modules/i18n';
import Event from 'PyzShopUi/scripts/etracker/events/Event';

// IMPORTANT: always keep initClientSideSentry on top of everything else
initClientSideSentry();

window.Helper = Helper;

export const globalEventBus = EventBus.getInstance();
const currentLanguage = LanguageHandler.getCurrentLanguage();
const middlewareEvenBusInstance = MiddlewareEventBus.getInstance();

const init = () => {
    setupGtm();

    HttpApi.init(currentLanguage);

    const { pathname } = document.location;

    // scrolling must be initialized first
    ScrollUtils.init(globalEventBus);
    LayoutSkeletonHandler.init();
    AccordionHandler.initAccordion(globalEventBus);
    AnchorScrollingHandler.init(globalEventBus);
    FirstPageFootnoteHandler.init();
    TabsAccordionContainerHandler.init(globalEventBus);
    CmsReadMoreHandler.init(globalEventBus);

    // lazy load cart-filled module if path ends with /cart
    if (/\/cart$/.test(pathname)) {
        import('../../../CartPage/Theme/default/views/cart-filled/cart-filled').then((module) => module.default());
    }

    BrandVideoHandler.init(globalEventBus);
    DataSwitchHandler.init(globalEventBus);
    ShippingAddressSelectHandler.init(globalEventBus);
    DataPasteHandler.init();
    DialogHandler.init(globalEventBus);
    ToastHandler.init(globalEventBus);
    FormValidatorHandler.init(globalEventBus);
    FormFieldFocusNextBehaviourHandler.init();
    HeaderHandler.initHeader(globalEventBus);
    PasswordInputHandler.init();
    FormSubmitButtonHandler.init();
    PrintHandler.init();
    RadioButtonOptionHandler.init(globalEventBus);
    TooltipHandler.initTooltips(globalEventBus);
    TrustedShopsHandler.init();
    BikeDetailHandler.init(globalEventBus);
    ArticleFlyToCartHandler.init(globalEventBus);
    LexiconMaterialHandler.init(globalEventBus);
    ReadMoreHandler.init();
    MoveToCartDialogHandler.init(globalEventBus);
    VideoHandler.init(globalEventBus);
    AudioHandler.init(globalEventBus);
    ResponsiveNavigationHandler.init(globalEventBus);

    // Spryker modules
    FootnoteFirstPageHandler.init();
    ProductWishListHandler.init();
    MyBikePageHandler.init();
    CatalogPageHandler.init();
    ProductSliderHandler.init();
    MainRubricHandler.init();
    ListAccordionHandler.init(globalEventBus);
    ClickTrackingService.firstPageInit();
    YoutubeIframeApiIntegrationHandler.init(globalEventBus);
    CatalogSearchPageHandler.init();
    MyLouisOrdersHandler.init();
    SearchSuggestionHandler.init();
    RecommendedDomainHandler.init();
    DefaultShippingAddressSelectHandler.init(globalEventBus);

    // CMS modules
    CmsSliderHandler.init();
    CmsBenefitsHandler.init();
    CmsSocialSharingHandler.init();
    CmsTestimonialsHandler.init();
    CmsStoreSpecialOpeningAccordionHandler.init();
    CmsStoreMapHandler.init();
    CmsStoreLocationDropdownHandler.init();
    CmsStoreSearchHandler.init();
    CmsYoutube.init();
    CmsVideoWithText.init();

    Event.init();

    Utils.fixSelectForSizeLarge(document.body);
    Utils.getDeviceInfo();
    UrlUtils.redirectCustomPath();

    if (window.lsg) {
        window.lsg.init(middlewareEvenBusInstance);
    }
};

const addEventListener = () => {
    globalEventBus.subscribe('window:resize', Utils.debounce(() => {
        globalEventBus.publish('window:resize:debounced');
    }, 250, false));

    window.onresize = () => {
        globalEventBus.publish('window:resize');
    };

    globalEventBus.subscribe('window:scroll', Utils.debounce(() => {
        globalEventBus.publish('window:scroll:debounced');
    }, 250, false));

    window.onscroll = (event: Event) => {
        globalEventBus.publish('window:scroll', event);
    };
};

document.addEventListener('DOMContentLoaded', (): void => {
    MaterialDesignComponentHandler.init();
    CommonEvents.firstPageInit();
    init();
    addEventListener();
    document.querySelector('html').classList.remove('no-js');
});

bootstrap();
