import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { CatalogPage } from 'PyzShopUi/scripts/catalog-page/components/CatalogPage';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';
import ProductList from 'PyzShopUi/scripts/catalog-page/components/catalog-page-content/product-list/ProductList';
import { SearchPageEvent } from 'PyzShopUi/scripts/shop-ui/components/search-page/events/searchPageEvent';
import FilterStateService from 'PyzShopUi/scripts/shop-ui/components/search-page/services/FilterStateService';
import { API_URL, emptyCatalogPageState, FILTER_TYPE_MAPPING } from './constants';
import {
    IApiQueryData, ISearchPageActionState,
    ISearchPageContentDataUpdatedPayload,
    ISearchResponse
} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { IProductItem } from 'PyzShopUi/scripts/shop-ui/interfaces';
import Breadcrumb from 'PyzShopUi/scripts/shop-ui/components/search-page/components/breadcrumb/Breadcrumb';
import { isCompactModeApplyForContext, isNavigationFilterApplyForContext } from './includes/helper/context-helper';
import ProductWishListHandler from 'PyzShopUi/scripts/product-wish-list/ProductWishListHandler';
import { productImageLocator } from 'PyzShopUi/scripts/shop-ui/includes/product-image-locator';
import { createCatalogPageStateWithInitResponse } from 'PyzShopUi/scripts/catalog-page/includes/helper/state-helper';
import { CatalogPageEvent } from 'PyzShopUi/scripts/catalog-page/events/catalogPageEvent';
import { VIEW_OPTION_LIST } from 'PyzShopUi/scripts/constants/bike-db';
import { ListNameContext } from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductItemListNameContext'
import Loading from 'PyzShopUi/scripts/react/common/components/Loading';
import { GloveBuyerGuideLanding } from "PyzShopUi/scripts/shop-ui/components/buyer-guide-landing/GloveBuyerGuideLanding";
import { JacketBuyerGuideLanding } from 'PyzShopUi/scripts/shop-ui/components/buyer-guide-landing/JacketBuyerGuideLanding';
import { BootBuyerGuideLanding } from "PyzShopUi/scripts/shop-ui/components/buyer-guide-landing/BootBuyerGuideLanding"
import { HelmetBuyerGuideLanding } from 'PyzShopUi/scripts/shop-ui/components/buyer-guide-landing/HelmetBuyerGuideLanding';
import {
    CommunicationSystemBuyerGuideLanding
} from 'PyzShopUi/scripts/shop-ui/components/buyer-guide-landing/CommunicationSystemBuyerGuideLanding';
import {IEtrackerProduct} from 'PyzShopUi/scripts/etracker/IEtrackerTracking';
import EtrackerTracking from 'PyzShopUi/scripts/etracker/EtrackerTracking';

(window as any).global = window;

const CATALOG_LIST_NAME = 'Catalog'

export default class CatalogPageHandler {
    public static init = (): void => {
        const catalogPageElement: HTMLElement = document.querySelector('.catalog-page-client-init');
        if (!catalogPageElement) {
            return;
        }

        CatalogPageHandler.renderProductWishListIcon();

        const elementDataSet = catalogPageElement.dataset;

        const isProductListRenderOnInit: boolean = elementDataSet.productListRenderOnInit === 'true';
        const defaultParameter: IApiQueryData = JSON.parse(elementDataSet.defaultParameters);

        const filterStateService = new FilterStateService(FILTER_TYPE_MAPPING);
        const isCompactMode: boolean = isCompactModeApplyForContext(defaultParameter);

        let catalogPageState: ISearchPageActionState;
        if (isProductListRenderOnInit) {
            catalogPageState = emptyCatalogPageState;
        } else {
            const initResponse: ISearchResponse = JSON.parse(elementDataSet.initResponse);
            catalogPageState = createCatalogPageStateWithInitResponse(initResponse, defaultParameter, filterStateService);
            CatalogPageHandler.trackFirstLoadProductListPage(elementDataSet);
        }

        MiddlewareEventBus.getInstance().subscribe((event: IReactEvent<ISearchPageContentDataUpdatedPayload>) => {
            switch (event.type) {
                case CatalogPageEvent.CATALOG_PAGE_CONTENT_DATA_INIT:
                    CatalogPageHandler.handleGTMEvent(event);
                    CatalogPageHandler.renderMyBikeHint(event);

                    if (event.payload.viewMode === VIEW_OPTION_LIST) {
                        CatalogPageHandler.renderCatalogProductList(
                            event,
                            isCompactMode
                        );
                    }

                    break;
                case SearchPageEvent.SEARCH_PAGE_CONTENT_DATA_UPDATED:
                    CatalogPageHandler.handleGTMEvent(event);
                    CatalogPageHandler.renderBreadcrumb(event);
                    CatalogPageHandler.renderCatalogProductList(
                        event,
                        isCompactMode
                    );
                    CatalogPageHandler.renderGloveBuyerGuideLanding(
                        isNavigationFilterApplyForContext(defaultParameter),
                        event
                    );
                    CatalogPageHandler.renderBootBuyerGuideLanding(
                        isNavigationFilterApplyForContext(defaultParameter),
                        event
                    );
                    CatalogPageHandler.renderJacketBuyerGuideLanding(
                        isNavigationFilterApplyForContext(defaultParameter),
                        event
                    );
                    CatalogPageHandler.renderHelmetBuyerGuideLanding(
                        isNavigationFilterApplyForContext(defaultParameter),
                        event
                    );
                    CatalogPageHandler.renderCommunicationSystemBuyerGuideLanding(
                        isNavigationFilterApplyForContext(defaultParameter),
                        event
                    )
                    CatalogPageHandler.renderMyBikeHint(event);

                    break;
                default:
                    break;
            }
        });

        const catalogPageElementRoot = createRoot(catalogPageElement);

        catalogPageElementRoot.render(
            <React.Suspense fallback={<Loading />}>
                <CatalogPage
                    apiUrl={API_URL}
                    defaultParameter={defaultParameter}
                    filterStateService={filterStateService}
                    isCompactMode={isCompactMode}
                    initialState={catalogPageState}
                    isStateNeedInitiated={isProductListRenderOnInit}
                />
            </React.Suspense>
        );
    };
    private static breadcrumbNavigationRoot;
    private static hbgTeaserElementRoot;
    private static gbgTeaserElementRoot;
    private static jbgTeaserElementRoot;
    private static bbgTeaserElementRoot;
    private static csbgTeaserElementRoot;
    private static productListRoot;
    private static myBikeHintElementRoot;

    public static handleGTMEvent(event: IReactEvent<ISearchPageContentDataUpdatedPayload>) {
        const { extraInformation } = event.payload;

        const gtmLoadEvents = extraInformation?.gtm;

        if (!window.hasOwnProperty('Louis') || !gtmLoadEvents) {
            return;
        }

        gtmLoadEvents.forEach(gtmLoadEvent => {
            window.Louis.Events.handleGTMEvent(gtmLoadEvent);
        });
    }

    public static renderBreadcrumb(event: IReactEvent<ISearchPageContentDataUpdatedPayload>) {
        const { breadcrumb } = event.payload;

        if (!breadcrumb || !breadcrumb.length) {
            return;
        }

        if (!this.breadcrumbNavigationRoot) {
            this.breadcrumbNavigationRoot = createRoot(document.querySelector('.breadcrumb-navigation'));
        }

        this.breadcrumbNavigationRoot.render(
            <Breadcrumb
                breadcrumb={breadcrumb}
            />
        );
    }

    public static renderCatalogProductList(
        event: IReactEvent<ISearchPageContentDataUpdatedPayload>,
        isCompactMode: boolean,
    ) {
        const {
            viewMode,
            searchResultItems,
            isSearchPageLoading,
            storeConfig,
            hasActiveFilter,
        } = event.payload;

        if (!this.productListRoot) {
            this.productListRoot = createRoot(document.querySelector('.product-list'));
        }

        this.productListRoot.render(
            <ListNameContext.Provider value={`${CATALOG_LIST_NAME}`}>
                <ProductList
                    products={searchResultItems as IProductItem[]}
                    isProgressing={isSearchPageLoading}
                    storeConfig={storeConfig}
                    viewMode={viewMode}
                    hasActiveFilter={hasActiveFilter}
                    isCompactMode={isCompactMode}
                />
            </ListNameContext.Provider>
        );
    }

    public static renderGloveBuyerGuideLanding(
        isNavigationFilterExist: boolean,
        event: IReactEvent<ISearchPageContentDataUpdatedPayload>
    ) {
        if (isNavigationFilterExist) {
            return;
        }

        const { categoryKey } = event.payload;

        const teaserElement = document.querySelector('.gbg-teaser-init');
        if (teaserElement === null) {
            return;
        }

        const bannerContent = teaserElement.getAttribute('data-banner-content') || ''

        if (!this.gbgTeaserElementRoot) {
            this.gbgTeaserElementRoot = createRoot(teaserElement);
        }

        this.gbgTeaserElementRoot.render(
            <GloveBuyerGuideLanding
                categoryKey={categoryKey}
                bannerContent={bannerContent}
            />
        );
    }

    public static renderJacketBuyerGuideLanding(
        isNavigationFilterExist: boolean,
        event: IReactEvent<ISearchPageContentDataUpdatedPayload>
    ) {
        if (isNavigationFilterExist) {
            return;
        }

        const { categoryKey } = event.payload;

        const teaserElement = document.querySelector('.jbg-teaser-init');
        if (teaserElement === null) {
            return;
        }

        const bannerContent = teaserElement.getAttribute('data-banner-content') || ''

        if (!this.jbgTeaserElementRoot) {
            this.jbgTeaserElementRoot = createRoot(teaserElement);
        }

        this.jbgTeaserElementRoot.render(
            <JacketBuyerGuideLanding
                categoryKey={categoryKey}
                bannerContent={bannerContent}
            />
        );
    }

    public static renderBootBuyerGuideLanding(
        isNavigationFilterExist: boolean,
        event: IReactEvent<ISearchPageContentDataUpdatedPayload>
    ) {
        if (isNavigationFilterExist) {
            return;
        }

        const { categoryKey } = event.payload;

        const teaserElement = document.querySelector('.bbg-teaser-init');
        if (teaserElement === null) {
            return;
        }

        const bannerContent = teaserElement.getAttribute('data-banner-content') || ''

        if (!this.bbgTeaserElementRoot) {
            this.bbgTeaserElementRoot = createRoot(teaserElement);
        }

        this.bbgTeaserElementRoot.render(
            <BootBuyerGuideLanding
                categoryKey={categoryKey}
                bannerContent={bannerContent}
            />
        );
    }

    public static renderHelmetBuyerGuideLanding(
        isNavigationFilterExist: boolean,
        event: IReactEvent<ISearchPageContentDataUpdatedPayload>
    ) {
        if (isNavigationFilterExist) {
            return;
        }

        const { categoryKey } = event.payload;

        const teaserElement = document.querySelector('.hbg-teaser-init');
        if (teaserElement === null) {
            return;
        }

        const bannerContent = teaserElement.getAttribute('data-banner-content') || ''

        if (!this.hbgTeaserElementRoot) {
            this.hbgTeaserElementRoot = createRoot(teaserElement);
        }

        this.hbgTeaserElementRoot.render(
            <HelmetBuyerGuideLanding
                categoryKey={categoryKey}
                bannerContent={bannerContent}
            />
        );
    }

    public static renderCommunicationSystemBuyerGuideLanding(
        isNavigationFilterExist: boolean,
        event: IReactEvent<ISearchPageContentDataUpdatedPayload>
    ) {
        if (isNavigationFilterExist) {
            return;
        }

        const { categoryKey } = event.payload;

        const teaserElement = document.querySelector('.csbg-teaser-init');
        if (teaserElement === null) {
            return;
        }

        const bannerContent = teaserElement.getAttribute('data-banner-content') || ''

        if (!this.csbgTeaserElementRoot) {
            this.csbgTeaserElementRoot = createRoot(teaserElement);
        }

        this.csbgTeaserElementRoot.render(
            <CommunicationSystemBuyerGuideLanding
                categoryKey={categoryKey}
                bannerContent={bannerContent}
            />
        );
    }

    private static renderProductWishListIcon() {
        const searchItemsResultElement: HTMLElement = document.querySelector('.page-layout-search-items-result');
        if (!searchItemsResultElement) {
            return;
        }

        ProductWishListHandler.renderIcons(searchItemsResultElement, productImageLocator, true);
    };

    private static renderMyBikeHint(event: IReactEvent<ISearchPageContentDataUpdatedPayload>) {
        const myBikeHintElement = document.querySelector('.my-bike-hint');
        if (myBikeHintElement === null || event.payload.isSearchPageLoading) {
            return;
        }

        if (!this.myBikeHintElementRoot) {
            this.myBikeHintElementRoot = createRoot(myBikeHintElement);
        }

        this.myBikeHintElementRoot.render(
            <div dangerouslySetInnerHTML={
                { __html: event.payload?.extraInformation?.myBikeHint }
            }>
            </div>
        );
    }

    private static trackFirstLoadProductListPage(elementDataSet: DOMStringMap) {
        const category: string = elementDataSet.categoryName;
        const eTrackerProductList: IEtrackerProduct[] = []
        document.querySelectorAll('[data-etracker-product]').forEach((element: HTMLElement) => {
            const eTrackerProduct = { ...JSON.parse(element.dataset.etrackerProduct) };

            if (category) {
                eTrackerProduct.category = [category];
            }

            eTrackerProductList.push(eTrackerProduct);
        })

        if (eTrackerProductList.length) {
            EtrackerTracking.viewProductList(eTrackerProductList);
        }
    }
}
