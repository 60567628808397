import * as React from 'react';
import WishListIcon from 'PyzShopUi/scripts/shop-ui/components/WishListIcon';
import {globalEventBus} from 'PyzShopUi/app';
import {EDialogEvents} from 'PyzShopUi/scripts/modules/events/dialogEvent';
import {EProductType} from 'PyzShopUi/scripts/shop-ui/constants';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import {ProductWishListEvent} from 'PyzShopUi/scripts/product-wish-list/events';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';
import {createProductWishListByType} from 'PyzShopUi/scripts/product-wish-list/includes/Helper';
import {
    IProductWishListEventAddProductPayload,
    IProductWishListEventPublishAllProductPayload,
    IProductWishListEventRemoveProductPayload
} from 'PyzShopUi/scripts/product-wish-list/interfaces';
import {ProductWishListInterface} from 'PyzShopUi/scripts/product-wish-list/includes/ProductWishListInterface';
import Loading from 'PyzShopUi/scripts/react/common/components/Loading';
import EtrackerTracking from 'PyzShopUi/scripts/etracker/EtrackerTracking';

interface ProductWishListIconProps {
    sku: string;
    etrackerProduct?: any;
    type: EProductType;
    isKeyboardAccessible?: boolean;

    imageLocator?(iconElement: HTMLElement): HTMLElement;
}

const ProductWishListIcon: React.FunctionComponent<ProductWishListIconProps> = props => {
    const { sku, type, imageLocator, isKeyboardAccessible, etrackerProduct } = props;
    const isUserLoggedIn = document.querySelector('body').dataset.loggedUser === '1';

    const ProductWishList = React.useRef<ProductWishListInterface>(createProductWishListByType(type, sku));
    const middlewareEventBus = React.useRef<MiddlewareEventBus>(MiddlewareEventBus.getInstance());
    const wishListIconElement = React.useRef<HTMLDivElement>();

    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!isUserLoggedIn) {
            return;
        }

        const subscription = middlewareEventBus.current.subscribe(
            (event: IReactEvent<IProductWishListEventPublishAllProductPayload>) => {
                if (event.type !== ProductWishListEvent.PUBLISH_ALL_PRODUCT) {
                    return;
                }

                setIsActive(ProductWishList.current.isInWishList(event.payload));
                setIsProcessing(false);
            }
        );

        middlewareEventBus.current.publish({
            type: ProductWishListEvent.GET_ALL_PRODUCT,
            payload: {}
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const onClick = () => {
        if (isUserLoggedIn) {
            handleWishListAction();

            return;
        }

        globalEventBus.publish(EDialogEvents.OPEN_DIALOG, {id: 'login-dialog'});
    };

    const handleWishListAction = () => {
        setIsProcessing(true);

        if (isActive) {
            removeProductFromWishList();
            EtrackerTracking.removeFromWatchlist(etrackerProduct);
        } else {
            addProductToWishList();
            EtrackerTracking.insertToWatchlist(etrackerProduct)
        }
    };

    const addProductToWishList = () => {
        const payload: IProductWishListEventAddProductPayload = {
            addProductDto: ProductWishList.current.createAddProductDTO(),
        };

        if (imageLocator) {
            payload.imageElement = imageLocator(wishListIconElement.current);
        }

        middlewareEventBus.current.publish<IProductWishListEventAddProductPayload>({
            type: ProductWishListEvent.ADD_PRODUCT,
            payload,
        });
    };

    const removeProductFromWishList = () => {
        middlewareEventBus.current.publish<IProductWishListEventRemoveProductPayload>({
            type: ProductWishListEvent.REMOVE_PRODUCT,
            payload: {
                type: ProductWishList.current.getProductType(),
                sku,
            }
        });
    };

    return (
        <div className="product-wish-list-icon">
            <React.Suspense fallback={<Loading />}>
                <WishListIcon
                    wishListIconRef={wishListIconElement}
                    isActive={isActive}
                    isProcessing={isProcessing}
                    onClick={onClick}
                    isKeyboardAccessible={isKeyboardAccessible}
                />
            </React.Suspense>
        </div>
    );
};

export default ProductWishListIcon;
