import * as React from 'react';
import ProductPrice from './ProductPrice';
import ProductBasicPrice from './ProductBasicPrice';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { sprykerProductImageSrcSet } from '../../../react/common/lib';
import Marker from '../Marker';
import { IProductItemBasicPrice, IProductItemImages } from '../../interfaces';
import { ClickTrackingService } from '../../services/ClickTrackingService';
import useArticleGTMEvent from '../../../react/common/hooks/useArticleGTMEvent';
import ProductWishListIcon from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductWishListIcon';
import { EProductType } from 'PyzShopUi/scripts/shop-ui/constants';
import { productImageLocator } from 'PyzShopUi/scripts/shop-ui/includes/product-image-locator';
import { buildConfigProduct, pushProductClickToDataLayer } from 'PyzShopUi/scripts/shop-ui/services/ProductClickTrackingService'
import { useProductListName } from './ProductItemListNameContext'
import ProductRating from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductRating';
import Utils from 'PyzShopUi/scripts/utils/utils';
import {IEtrackerProduct} from 'PyzShopUi/scripts/etracker/IEtrackerTracking';

interface IProductItemProps {
    sku: string;
    name: string;
    brandName: string;
    url: string;
    images: IProductItemImages;
    hasMultiplePrices?: boolean;
    catalogPrice?: number;
    customerPrice?: number;
    merchantPrice?: number;
    basicPrice?: IProductItemBasicPrice;
    type: EProductType;

    isNew?: boolean;
    isSale?: boolean;
    suitability?: number;
    isCompactMode?: boolean;
    ratingAverage?: number;
    catalogOrigin?: number;

    currency: string;
    currencyFactor: number;

    clickTrackingParams?: object;
    gtm?: any[];
    useLazyLoad?: boolean;
    isKeyboardAccessible?: boolean;
    isWishlistAccessible?: boolean;
    isInViewport?: boolean;
    seoTitleSecondary?: string;
    dataTrackAttr?: {};
    eTrackerProduct?: IEtrackerProduct;
}

const ProductItem: React.FunctionComponent<IProductItemProps> = props => {
    const {
        sku,
        name,
        brandName,
        isNew,
        suitability,
        hasMultiplePrices,
        catalogPrice,
        customerPrice,
        merchantPrice,
        ratingAverage,
        url,
        images,
        basicPrice,
        currency,
        isCompactMode,
        clickTrackingParams,
        gtm,
        type,
        catalogOrigin,
        useLazyLoad,
        isKeyboardAccessible = true,
        isWishlistAccessible = true,
        isInViewport = true,
        seoTitleSecondary,
        dataTrackAttr,
        eTrackerProduct
    } = props;

    const listName = useProductListName();
    const { translate } = useTranslationHook();
    const imgAlt = name?.length < 10 ? `${translate('product', { ns: 'url' })} ${name}` : name;

    const isNewFlagAllowed = isNew === true;
    const attrs = {
        "data-sku": sku,
        "data-type": type,
        "data-etracker-product": JSON.stringify(eTrackerProduct)
    };

    const displayNewFlag = () => {
        if (!isNewFlagAllowed) {
            return null;
        }

        return (
            <Marker label={translate('New!', { ns: 'article-list' }).toLocaleUpperCase()} color='blue' />
        );
    };

    const displayProductRating = () => {
        if (!(ratingAverage && ratingAverage > 0)) {
            return null;
        }

        return (
            <ProductRating ratingAverage={ratingAverage}/>
        );
    };

    const displayProductBasicPrice = () => {
        if (!basicPrice || isCompactMode) {
            return;
        }

        return (
            <ProductBasicPrice
                unitName={basicPrice.unitName}
                unitAmount={basicPrice.unitAmount}
                unitValue={basicPrice.unitValue}
                currency={currency}
            />
        );
    };

    const displaySuitability = () => {
        if (!suitability) {
            return null;
        }

        const suitabilityLabel = Math.round(suitability) + '%';

        return (
            <div className='product-suitability'>
                <div className='sub-title'>
                    {translate('Suitability for type of rider', { ns: 'helmet-buyer-guide' })}:
                </div>
                <div className='success progress'>
                    <div className='progress-meter' style={{ width: suitabilityLabel }}>
                        {suitabilityLabel}
                    </div>
                </div>
            </div>
        );
    };

    const displayProductWishListIcon = () => {
        if (!sku || !type) {
            return null;
        }

        return <ProductWishListIcon etrackerProduct={eTrackerProduct} sku={sku} type={type} imageLocator={productImageLocator} isKeyboardAccessible={isWishlistAccessible} />
    }

    const trackClick = () => {
        const products = buildConfigProduct({
            id: sku,
            name,
            customerPrice,
            merchantPrice
        })

        pushProductClickToDataLayer({ actionField: { list: listName }, products });
    }

    const onClick = () => {
        trackClick();

        if (gtm) {
            const gtmData = {
                extraInformation: {
                    gtm,
                },
            }

            useArticleGTMEvent(gtmData);
        }

        if (!clickTrackingParams) {
            return;
        }

        ClickTrackingService.sendClickTracking(clickTrackingParams);
    }

    const displayProductImage = () => {
        const imgId = `product-image-cover-${sku}-${Utils.getRandomId()}`;
        return (
            <div className={`product-image-cover ${useLazyLoad ? 'lazy-image-container' : ''}`}>
                <a itemProp='url' className='product-image-cover__full-link' href={url} {...(isInViewport ? { "aria-labelledby": imgId } : { 'aria-label': imgAlt })} tabIndex={isKeyboardAccessible ? 0 : -1} aria-hidden={isKeyboardAccessible ? "false" : "true"} />
                {useLazyLoad ? productImage(imgId) : productDirectImage(imgId)}
            </div>
        )
    }

    const productImage = (id?: string) => {
        return (
            <img
                id={id}
                alt={imgAlt}
                src=""
                className='product-image lazyload'
                data-src={images.externalUrlThumbnail}
                data-srcset={sprykerProductImageSrcSet(images)}
                data-fly-to-cart-object='true'
            />
        );
    }

    const productDirectImage = (id?: string) => {
        return (
            <img
                id={id}
                alt={imgAlt}
                src={images.externalUrlThumbnail}
                className="product-image direct-image"
                srcSet={sprykerProductImageSrcSet(images)}
                data-fly-to-cart-object='true'
                height='400' width='331'
            />
        );
    }

    const priceDataTractAttr = {
            "data-track-id": 'priceContainer'
        };

    return (
        <article {...dataTrackAttr} className='product-item' onClick={onClick}>
            {displayNewFlag()}

            <div className={'product-wish-list-icon-container'} {...attrs}>
                {displayProductWishListIcon()}
            </div>

            <div className="product-item__image-container">
                {displayProductImage()}
                {displayProductRating()}
            </div>

            {displaySuitability()}
            <div className='product-info paragraph-s'>
                <div className='product-info__brand-name'>
                    {brandName}
                </div>

                <div className='product-info__product-name'>
                    <a href={url} tabIndex={-1}>
                        {name} {seoTitleSecondary}
                    </a>
                </div>

                <ProductPrice
                    catalogPrice={catalogPrice}
                    customerPrice={customerPrice}
                    merchantPrice={merchantPrice}
                    hasMultiplePrices={hasMultiplePrices}
                    currency={currency}
                    catalogOrigin={catalogOrigin}
                    dataTrackAttr={priceDataTractAttr}
                />
                {displayProductBasicPrice()}
            </div>
        </article>
    );
};

ProductItem.defaultProps = {
    isNew: false,
    isSale: false,
    clickTrackingParams: null,
    gtm: null,
    catalogOrigin: null,
};

export default ProductItem;
