import * as React from 'react';
import {
    DEFAULT_CONTEXT_NAME,
    PARAM_CATEGORY_KEY,
    PARAM_PAGE,
    PARAM_PER_PAGE,
    PARAM_SORT,
    PARAM_VIEW_MODE,
    STATE_VIEW_MODE,
    VIEW_MODE_GRID,
    PARAM_FILTER_TOKEN,
    PARAM_BACK_KEY, CATALOG_PAGE_CONTEXT_NAME
} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import { SearchPageEvent } from 'PyzShopUi/scripts/shop-ui/components/search-page/events/searchPageEvent';
import {
    buildSearchPageState,
    standardizedCategoryFilter
} from 'PyzShopUi/scripts/shop-ui/components/search-page/includes/searchPageHelper';
import {
    IApiQueryData,
    ISearchPageActionState,
    ISearchPageContentDataUpdatedPayload,
    ISearchResponse
} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import {
    getPageService,
    ISearchPageService
} from 'PyzShopUi/scripts/shop-ui/components/search-page/services/SearchPageService';
import FilterStateService from 'PyzShopUi/scripts/shop-ui/components/search-page/services/FilterStateService';
import { createActionCreator, ThunkActionCreator } from 'PyzShopUi/scripts/shop-ui/includes/enhanced-reducer';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import {
    updateNavigationActiveCategoryKey
} from 'PyzShopUi/scripts/shop-ui/components/search-page/actions/categoriesTree';
import { PARAM_BIKE_VIEW } from 'PyzShopUi/scripts/constants/bike-db';
import {
    createStringParamFromApiQueryData,
    getApiQueryDataFromLocation
} from 'PyzShopUi/scripts/shop-ui/includes/query-string-helper';
import { CatalogPageEvent } from 'PyzShopUi/scripts/catalog-page/events/catalogPageEvent';
import EtrackerTracking from 'PyzShopUi/scripts/etracker/EtrackerTracking';

const UPDATE_SEARCH_PAGE_STATE = 'UPDATE_SEARCH_PAGE_STATE';
const BEGIN_SEARCH_PAGE_LOADING = 'BEGIN_SEARCH_PAGE_LOADING';
const END_SEARCH_PAGE_LOADING = 'END_SEARCH_PAGE_LOADING';
const UPDATE_SKIP_UPDATE_FROM_API_QUERY_PARAMS = 'UPDATE_SKIP_UPDATE_FROM_API_QUERY_PARAMS';
const UPDATE_ACTIVE_CATEGORY_NAME = 'UPDATE_ACTIVE_CATEGORY_NAME';
const UPDATE_HEAD_TITLE = 'UPDATE_HEAD_TITLE';

export const updateSearchPageState = createActionCreator<ISearchPageActionState>(UPDATE_SEARCH_PAGE_STATE);
export const updateSkipUpdateFromApiQueryParams = createActionCreator<boolean>(UPDATE_SKIP_UPDATE_FROM_API_QUERY_PARAMS);
export const beginSearchPageLoading = createActionCreator(BEGIN_SEARCH_PAGE_LOADING);
export const endSearchPageLoading = createActionCreator(END_SEARCH_PAGE_LOADING);
export const updateActiveCategoryName = createActionCreator<string>(UPDATE_ACTIVE_CATEGORY_NAME);
export const updateHeadTitle = createActionCreator<string>(UPDATE_HEAD_TITLE);

let emptyFilterSearchApiSuffix: string = '';
let searchPageRequestId: number = 0;
let searchPageService: ISearchPageService;

export const initSearchPageService: ThunkActionCreator<ISearchPageActionState> = (
    apiUrl: string,
    defaultParameter: IApiQueryData,
    filterStateService: FilterStateService
) => (dispatch, getState) => {
    const state = getState();

    searchPageService = getPageService(apiUrl, filterStateService, false);
    searchPageService.setDefaultQueryObject({ ...defaultParameter });
    searchPageService.setDispatch(dispatch);
    searchPageService.addDefaultBrowserQuery(PARAM_BACK_KEY, defaultParameter[PARAM_BACK_KEY] ?? null);
    searchPageService.updateBrowserUrl(state.apiQueryData);

    MiddlewareEventBus.getInstance().publish<ISearchPageContentDataUpdatedPayload>(
        {
            type: CatalogPageEvent.CATALOG_PAGE_CONTENT_DATA_INIT,
            payload: {
                searchResultItems: state.searchResultItems,
                isSearchPageLoading: false,
                storeConfig: state.storeConfig,
                viewMode: state.viewMode,
                extraInformation: state.extraInformation
            },
        },
    );
};

export const initSearchPageData: ThunkActionCreator<ISearchPageActionState> = (
    apiUrl: string,
    defaultParameter: IApiQueryData,
    filterStateService: FilterStateService,
    excludeQueryParamsFromState: string[] = [],
    emptyFilterSearchApiSuffixString?: string,
    contextName: string = DEFAULT_CONTEXT_NAME,
    searchResult: ISearchResponse = null
) => dispatch => {
    const isSearchContext = searchResult !== null;
    searchPageService = getPageService(apiUrl, filterStateService, isSearchContext);
    searchPageService.setDefaultQueryObject({ ...defaultParameter });
    searchPageService.addDefaultBrowserQuery(PARAM_BACK_KEY, defaultParameter[PARAM_BACK_KEY] ?? null);

    if (defaultParameter.hasOwnProperty(STATE_VIEW_MODE)) {
        searchPageService.setApiUrlSuffix(defaultParameter[STATE_VIEW_MODE]);
    }

    if (emptyFilterSearchApiSuffixString) {
        emptyFilterSearchApiSuffix = emptyFilterSearchApiSuffixString;
    }

    searchPageService.setDispatch(dispatch);

    const apiQueryDataFromLocation = getApiQueryDataFromLocation();

    if (apiQueryDataFromLocation[PARAM_BIKE_VIEW]) {
        const viewModeFromQuery = apiQueryDataFromLocation[PARAM_BIKE_VIEW].toString();
        searchPageService.setApiUrlSuffix(viewModeFromQuery);
    }

    const apiQueryData: IApiQueryData = {
        ...defaultParameter,
        ...apiQueryDataFromLocation
    };

    const currentState: ISearchPageActionState = null;
    const currentApiQueryData: IApiQueryData = apiQueryData;

    const hasFiltersSelectedState: boolean = hasFiltersSelected({ ...apiQueryData });

    if (!hasFiltersSelectedState && emptyFilterSearchApiSuffix) {
        searchPageService.setApiUrlSuffix(emptyFilterSearchApiSuffix);
    }

    if (searchResult !== null) {
        searchResult.requestId = searchPageRequestId;

        processSearchData(
            dispatch,
            currentState,
            searchResult,
            false,
            currentApiQueryData,
            filterStateService,
            excludeQueryParamsFromState,
            contextName,
            false,
            hasFiltersSelectedState
        );
    } else {
        dispatch(beginSearchPageLoading());

        searchPageService.getData(apiQueryData, searchPageRequestId).then(data => {
            processSearchData(
                dispatch,
                currentState,
                data,
                true,
                currentApiQueryData,
                filterStateService,
                excludeQueryParamsFromState,
                contextName,
                false,
                hasFiltersSelectedState
            );
        });
    }
};

export const updateSearchPageData: ThunkActionCreator<ISearchPageActionState> = (
    apiQueryData: IApiQueryData,
    filterStateService: FilterStateService,
    isBack: boolean = false,
    excludeQueryParamsFromState: string[] = [],
    stateAsQueryParamMap: Map<string, string> = new Map(),
    contextName: string = DEFAULT_CONTEXT_NAME
) => {
    searchPageRequestId++;

    return (dispatch, getState) => {
        const currentState: ISearchPageActionState = getState();
        const currentApiQueryData: IApiQueryData = {
            ...currentState.apiQueryData,
            ...apiQueryData,
        };

        stateAsQueryParamMap.forEach((queryKey: string, stateKey: string): void => {
            if (currentState[stateKey]) {
                currentApiQueryData[queryKey] = currentState[stateKey];
            }
        });

        if (currentApiQueryData[PARAM_BIKE_VIEW]) {
            currentState[PARAM_VIEW_MODE] = currentApiQueryData[PARAM_BIKE_VIEW];
        }

        const hasFiltersSelectedState: boolean = hasFiltersSelected({ ...currentApiQueryData });

        dispatch(beginSearchPageLoading());
        MiddlewareEventBus.getInstance().publish<ISearchPageContentDataUpdatedPayload>(
            {
                type: SearchPageEvent.SEARCH_PAGE_CONTENT_DATA_UPDATED,
                payload: {
                    searchResultItems: [],
                    isSearchPageLoading: true,
                    storeConfig: currentState.storeConfig,
                    viewMode: currentState.viewMode,
                    hasFiltersSelected: hasFiltersSelectedState,
                    breadcrumb: currentState.extraInformation ? currentState.extraInformation.breadcrumb : null,
                    hasActiveFilter: false,
                    categoryKey: currentApiQueryData.categoryKey || null,
                },
            },
        );

        if (!isBack) {
            const urlPath: string = currentState.navigationActiveUrl || null;
            searchPageService.updateBrowserUrl(currentApiQueryData, urlPath);
        }

        if (emptyFilterSearchApiSuffix) {
            if (!hasFiltersSelectedState) {
                searchPageService.setApiUrlSuffix(emptyFilterSearchApiSuffix);
            } else if (currentState.viewMode) {
                searchPageService.setApiUrlSuffix(currentState.viewMode);
            } else if (searchPageService.getDefaultQueryObject().hasOwnProperty(STATE_VIEW_MODE)) {
                // fallback in case user uses browser back button
                // probably only useful on bike search page because page calls 3 different api endpoints
                searchPageService.setApiUrlSuffix(searchPageService.getDefaultQueryObject()[STATE_VIEW_MODE]);
            }
        }

        searchPageService.getData(currentApiQueryData, searchPageRequestId).then(data => {
            processSearchData(
                dispatch,
                currentState,
                data,
                false,
                currentApiQueryData,
                filterStateService,
                excludeQueryParamsFromState,
                contextName,
                isBack,
                hasFiltersSelectedState
            );
        });
    };
};

const processSearchData = (
    dispatch: React.Dispatch<any>,
    currentState: ISearchPageActionState,
    searchFilterResultData: ISearchResponse,
    isInitialRequest: boolean,
    currentApiQueryData: IApiQueryData,
    filterStateService: FilterStateService,
    excludeQueryParamsFromState: string[],
    contextName: string,
    isBack: boolean,
    hasFiltersSelectedState: boolean
) => {
    if (searchPageRequestId !== searchFilterResultData.requestId) {
        return;
    }

    const defaultQueryObject: IApiQueryData = searchPageService.getDefaultQueryObject();
    const defaultCategoryParam: string = defaultQueryObject[PARAM_CATEGORY_KEY] || null;
    const categoryKey: string = currentApiQueryData.categoryKey || null;

    standardizedCategoryFilter(searchFilterResultData.filters, currentApiQueryData, defaultCategoryParam);

    let searchPageState = buildSearchPageState(
        searchFilterResultData,
        currentApiQueryData,
        filterStateService,
        excludeQueryParamsFromState,
        contextName
    );

    searchPageState = {
        ...searchPageState,
        skipUpdateBaseOnApiQueryParams: true
    };

    if (defaultQueryObject.hasOwnProperty(STATE_VIEW_MODE) && !currentState?.viewMode && !currentApiQueryData.hasOwnProperty(PARAM_BIKE_VIEW)) {
        searchPageState = {
            ...searchPageState,
            viewMode: defaultQueryObject.viewMode
        };
    }

    if (currentApiQueryData.hasOwnProperty(PARAM_BIKE_VIEW)) {
        searchPageState = {
            ...searchPageState,
            viewMode: currentApiQueryData[PARAM_BIKE_VIEW]
        };

        currentState = {
            ...currentState,
            viewMode: currentApiQueryData[PARAM_BIKE_VIEW]
        };
    }

    if (defaultQueryObject.hasOwnProperty(PARAM_CATEGORY_KEY) && isInitialRequest) {
        searchPageState = {
            ...searchPageState,
            navigationActiveCategoryKey: defaultQueryObject[PARAM_CATEGORY_KEY],
            initCategoryKey: defaultQueryObject[PARAM_CATEGORY_KEY]
        };
    }

    if (isInitialRequest) {
        searchPageService.updateBrowserUrl(searchPageState.apiQueryData);
    }

    if (isBack) {
        dispatch(updateHeadTitle(currentApiQueryData[PARAM_CATEGORY_KEY]));
        dispatch(updateNavigationActiveCategoryKey(currentApiQueryData[PARAM_CATEGORY_KEY]));
        dispatch(updateActiveCategoryName(currentApiQueryData[PARAM_CATEGORY_KEY]));
    }

    dispatch(updateSearchPageState(searchPageState));
    dispatch(endSearchPageLoading());
    const currency = searchFilterResultData?.storeConfig?.currency ?? null;

    if (((defaultQueryObject.searchType && defaultQueryObject.searchType === 'product') || contextName === CATALOG_PAGE_CONTEXT_NAME) &&
        searchFilterResultData.resultItems.length && currency
    ) {
        const breadcrumb = searchFilterResultData.extraInformation ? searchFilterResultData.extraInformation.breadcrumb : [];
        const lastCategory = breadcrumb[breadcrumb.length - 1]?.label ?? null;
        const etrackerProductList = EtrackerTracking.buildEtrackerProductList(
            searchFilterResultData.resultItems,
            currency,
            lastCategory
        )
        const listType = EtrackerTracking.getViewProductListType(defaultQueryObject)
        EtrackerTracking.viewProductList(etrackerProductList, listType)
    }

    MiddlewareEventBus.getInstance().publish<ISearchPageContentDataUpdatedPayload>(
        {
            type: SearchPageEvent.SEARCH_PAGE_CONTENT_DATA_UPDATED,
            payload: {
                searchResultItems: searchFilterResultData.resultItems,
                isSearchPageLoading: false,
                storeConfig: searchFilterResultData.storeConfig,
                viewMode: currentState?.viewMode || searchPageState?.viewMode || VIEW_MODE_GRID, // fallback viewMode when using browser back button?
                hasFiltersSelected: hasFiltersSelectedState,
                breadcrumb: searchFilterResultData.extraInformation ? searchFilterResultData.extraInformation.breadcrumb : null,
                hasActiveFilter: filterStateService.hasActiveFilter(searchFilterResultData.filters),
                extraInformation: searchFilterResultData.extraInformation,
                categoryKey,
            },
        },
    );
};

export const setSearchPageServiceApiUrlSuffix: ThunkActionCreator<ISearchPageActionState> = (suffix: string) => _ => {
    searchPageService.setApiUrlSuffix(suffix);
};

export const hasFiltersSelected = (apiQueryData: IApiQueryData): boolean => {
    if (apiQueryData[PARAM_FILTER_TOKEN]) {
        delete apiQueryData[PARAM_FILTER_TOKEN];
    }
    if (apiQueryData[PARAM_PAGE] === '1') {
        delete apiQueryData[PARAM_PAGE];
    }
    if (apiQueryData[PARAM_SORT]) {
        delete apiQueryData[PARAM_SORT];
    }
    if (apiQueryData[PARAM_BIKE_VIEW]) {
        delete apiQueryData[PARAM_BIKE_VIEW];
    }
    if (apiQueryData[PARAM_VIEW_MODE]) {
        delete apiQueryData[PARAM_VIEW_MODE];
    }
    if (apiQueryData[PARAM_PER_PAGE]) {
        delete apiQueryData[PARAM_PER_PAGE];
    }
    const queryData: string = createStringParamFromApiQueryData(apiQueryData, true);

    return !!queryData;
};
