import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import MiddlewareEventBus from '../../react/common/lib/MiddlewareEventBus';
import {ISearchResponse, ISearchResultItem, IStoreConfig} from '../../shop-ui/components/search-page/interfaces';
import { IProductItem } from '../../shop-ui/interfaces';
import { ProductSliderEvent } from '../events';
import { ISliderContainerProps, ISliderDataUpdatedPayload, ISliderProps } from '../interfaces';
import {getApiQueryDataFromLocation} from 'PyzShopUi/scripts/shop-ui/includes/query-string-helper';
import EtrackerTracking from 'PyzShopUi/scripts/etracker/EtrackerTracking';
import {CatalogContextName} from 'PyzShopUi/scripts/catalog-page/constants';
import {COMPATITBLE_PRODUCT_PARAM, PARAM_CONTEXT} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';

type HighOrderComponent<T> = (Component: React.FunctionComponent) => React.FunctionComponent<T>;

export function withAPI(defaultMinimumItemForDisplay: number): HighOrderComponent<ISliderProps> {
    function withAPIComponent(FunctionComponent: React.FunctionComponent<ISliderContainerProps>): React.FunctionComponent<ISliderProps> {
        const wrappedComponent: React.FunctionComponent<ISliderProps> = props => {
            const { apiUrl, minimumItemForDisplay, skeletonName, useLazyLoad } = props;

            const [isLoaded, setLoaded] = React.useState<boolean>(false);
            const [items, setItems] = React.useState<IProductItem[]>([]);
            const [storeConfig, setStoreConfig] = React.useState<IStoreConfig>(null);

                const handleSendProductsToEtracker = (resultItems: ISearchResultItem[], currency: string, apiUrl: URL) => {
                let apiQueryDataFromLocation = getApiQueryDataFromLocation(apiUrl);
                if (!resultItems.length || apiQueryDataFromLocation[PARAM_CONTEXT] && apiQueryDataFromLocation[PARAM_CONTEXT] === CatalogContextName.RECENTLY_VIEWED_PRODUCT) {
                    return;
                }
                const apiUrlString = apiUrl.toString()
                if (apiUrlString.includes(COMPATITBLE_PRODUCT_PARAM)) {
                    const productSku = apiUrlString.split("/").filter(Boolean).pop()
                    apiQueryDataFromLocation = {
                        ...apiQueryDataFromLocation,
                        context: CatalogContextName.COMPATIBLE_PRODUCT_SLIDER,
                        sku: productSku
                    }
                }
                const listType = EtrackerTracking.getViewProductListType(apiQueryDataFromLocation)
                const eTrackerProductList = EtrackerTracking.buildEtrackerProductList(resultItems, currency)
                EtrackerTracking.viewProductList(eTrackerProductList, listType)
            };
            const onComponentRendered = () => {
                axios.get(apiUrl.toString()).then((result: AxiosResponse<ISearchResponse>) => {
                    MiddlewareEventBus.getInstance().publish<ISliderDataUpdatedPayload>(
                        {
                            type: ProductSliderEvent.PRODUCT_SLIDER_DATA_UPDATED,
                            payload: {
                                skeletonName,
                            },
                        },
                    );

                    setLoaded(true);
                    setItems(result.data.resultItems as IProductItem[]);
                    handleSendProductsToEtracker(result.data.resultItems, result.data.storeConfig.currency, apiUrl)
                    setStoreConfig(result.data.storeConfig);
                });
            };

            React.useEffect(onComponentRendered, []);

            const minItemForDisplay = !minimumItemForDisplay ? defaultMinimumItemForDisplay : minimumItemForDisplay;

            if (false === isLoaded || items.length < minItemForDisplay) {
                return null;
            }

            return <FunctionComponent items={items} storeConfig={storeConfig} {...props} />;
        };

        wrappedComponent.displayName = 'withAPI';

        return wrappedComponent;
    }

    return withAPIComponent;
}
