import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface ICommunicationSystemBuyerGuideLadingState {
    categoryKey: string;
    bannerContent: string;
}

const categoryKeysHavingBuyerGuide = ['m-590', 'c-910', 'c-909'];

const CommunicationSystemBuyerGuideComponent: React.FunctionComponent<ICommunicationSystemBuyerGuideLadingState> = props => {
    const { categoryKey, bannerContent } = props;

    if (!categoryKeysHavingBuyerGuide.includes(categoryKey) || !bannerContent) {
        return null;
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: bannerContent }} />
    );
};

export const CommunicationSystemBuyerGuideLanding = withMemo(CommunicationSystemBuyerGuideComponent);
