import EtrackerTracking from 'PyzShopUi/scripts/etracker/EtrackerTracking';
import {EtrackerEventNames, IEtrackerProduct} from 'PyzShopUi/scripts/etracker/IEtrackerTracking';
import Utils from 'PyzShopUi/scripts/utils/utils';

export default class Event {
    public static init() {
        const removeWatchListButtons: NodeListOf<HTMLElement> = document.querySelectorAll('[data-etrack-action]');
        removeWatchListButtons.forEach((button) => {
            const buttonData = button.dataset;

            switch(buttonData.etrackAction) {
                case EtrackerEventNames.REMOVE_FROM_WATCH_LIST:
                    button.addEventListener('click', function() {
                        EtrackerTracking.removeFromWatchlist(Event.functionConvertDataETracker(JSON.parse(buttonData.etrackerProduct)))
                    })
                    break;
                case EtrackerEventNames.INSERT_TO_WATCH_LIST:
                    button.addEventListener('click', function() {
                        EtrackerTracking.insertToWatchlist(Event.functionConvertDataETracker(JSON.parse(buttonData.etrackerProduct)))
                    })
                    break;
                case EtrackerEventNames.INSERT_TO_BASKET:
                    button.addEventListener('click', function() {
                        EtrackerTracking.insertToBasket(Event.functionConvertDataETracker(JSON.parse(buttonData.etrackerProduct)), 1)
                    })
                    break;
                case EtrackerEventNames.REMOVE_FROM_BASKET:
                    button.addEventListener('click', function() {
                        EtrackerTracking.removeFromBasket(Event.functionConvertDataETracker(JSON.parse(buttonData.etrackerProduct)))
                    })
                    break;
            }
        })
    }

    public static functionConvertDataETracker = (ETrackerData) =>
    {
        ETrackerData.price = Utils.formatPrice(ETrackerData.price);

        return ETrackerData;
    }
}
