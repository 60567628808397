import queryString, { StringifiableRecord } from 'query-string';
import { Location } from 'history';
import { IApiQueryData } from '../../components/search-page/interfaces';

export const getApiQueryDataFromLocation = (newLocation?: Location|URL) => {
    return queryString.parse(
        newLocation?.search || location.search,
        {
            arrayFormat: 'bracket',
        }
    ) as IApiQueryData;
};

export const getApiQueryDataFromParameters = (parameters: string) => {
    return queryString.parse(
        parameters,
        {
            arrayFormat: 'bracket',
        }
    ) as IApiQueryData;
}

export const createStringParamFromApiQueryData = (apiQueryData: IApiQueryData, skipEmptyString = false) => {
    return queryString.stringify(
        apiQueryData as StringifiableRecord,
        {
            skipNull: true,
            arrayFormat: 'bracket',
            skipEmptyString
        }
    );
}

export const mergeQueryIntoUrl = (url: string, query: object) => {
    const newUrl = new URL(url, window.location.origin);
    const queryData = getApiQueryDataFromParameters(newUrl.search);
    const mergedQuery: IApiQueryData = {
        ...queryData,
        ...query
    }

    newUrl.search = createStringParamFromApiQueryData(mergedQuery);

    return newUrl;
}
