export const PARAM_SORT = 'sort';
export const PARAM_PER_PAGE = 'ipp';
export const PARAM_PAGE = 'page';
export const PARAM_SEARCH = 'search_term';
export const PARAM_CONTEXT = 'context';
export const PARAM_PROMOTION_CODE = 'promotionCode';
export const PARAM_VIEW_MODE = 'viewMode';
export const PARAM_CATEGORY_KEY = 'categoryKey';
export const PARAM_SEARCH_TYPE = 'searchType';
export const PARAM_FILTER_TOKEN = '_t';
export const PARAM_BACK_KEY = 'back';
export const PARAM_SHOW_TAB_BAR = 'showTabBar';
export const PARAM_EXTERNAL_ID ='externalId';
export const PARAM_BIKE_CODE = 'bikeCode';
export const PARAM_SEARCH_TERM = 'q';
export const PARAM_PLACEMENT = 'placement';
export const PARAM_SKU = 'sku';

export const STATE_EMPTY_FILTER_API_SUFFIX = 'emptyFilterApiSuffix';
export const STATE_VIEW_MODE = 'viewMode';
export const VIEW_MODE_GRID: string = 'grid';
export const VIEW_MODE_LIST: string = 'list';

export const FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS = 800;
export const FILTER_ITEM_SEARCH_DEBOUNCE_TIME_MS = 400;
export const PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS = 800;

export const MAX_ITEM_FOR_ENABLE_SCROLL = 10;
export const MAX_CATEGORY_ITEM_FOR_ENABLE_SCROLL = 15;

export const DEFAULT_CONTEXT_NAME = 'DEFAULT';
export const CATALOG_PAGE_CONTEXT_NAME = 'CATALOG_PAGE';
export const COMPATITBLE_PRODUCT_PARAM = 'compatible-product';

