import * as React from 'react';
import { createRoot } from 'react-dom/client';
import ProductWishListIcon from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductWishListIcon';
import { EProductType } from 'PyzShopUi/scripts/shop-ui/constants';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import { productWishListService } from 'PyzShopUi/scripts/product-wish-list/services/ProductWishListService';
import { ProductWishListEvent } from 'PyzShopUi/scripts/product-wish-list/events';
import {
    IProductWishListEventAddProductPayload,
    IProductWishListEventPublishAllProductPayload,
    IProductWishListEventRemoveProductPayload
} from 'PyzShopUi/scripts/product-wish-list/interfaces';
import { IToastPayload } from 'PyzShopUi/scripts/interfaces/IToastPayload';
import i18next from 'i18next';
import { ToastEvent } from 'PyzShopUi/scripts/react/common/events/toastEvent';
import { EWishListEvent } from 'PyzShopUi/scripts/modules/events/wishListEvent';
import EventBus from 'PyzShopUi/scripts/utils/eventBus';
import { getRemoveProductServiceFunctionByType } from 'PyzShopUi/scripts/product-wish-list/includes/Helper';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

const PRODUCT_WISH_LIST_CONTAINER = '.product-wish-list-icon-container';

class ProductWishList {
    private middlewareEventBus: MiddlewareEventBus;
    private eventBus: EventBus;

    private concreteProductWishLists: string[] = [];
    private abstractProductWishLists: string[] = [];

    private getWistListAndPublishingObserver = new Subject();
    private getWishListDebounceTime = 100;

    constructor(middleEventBus: MiddlewareEventBus, eventBus: EventBus) {
        this.middlewareEventBus = middleEventBus;
        this.eventBus = eventBus;

        this.addEventListener();
    }

    private addEventListener() {
        this.middlewareEventBus.subscribe(event => {
            if (event.type === ProductWishListEvent.GET_ALL_PRODUCT) {
                this.getWistListAndPublishingObserver.next();
            }

            if (event.type === ProductWishListEvent.ADD_PRODUCT) {
                this.addProduct(event.payload);
            }

            if (event.type === ProductWishListEvent.REMOVE_PRODUCT) {
                this.removeProduct(event.payload);
            }
        });

        this.getWistListAndPublishingObserver.pipe(
            debounceTime(this.getWishListDebounceTime),
            switchMap(() => productWishListService.getProducts())
        ).subscribe(response => {
            this.concreteProductWishLists = response.concreteProductWishLists || [];
            this.abstractProductWishLists = response.abstractProductWishLists || [];

            this.publishProductsWishList();
        });
    }

    private addProduct(payload: IProductWishListEventAddProductPayload) {
        const { addProductDto, imageElement } = payload;

        productWishListService.addProduct(addProductDto).then(response => {
            this.concreteProductWishLists = response.concreteProductWishLists;
            this.abstractProductWishLists = response.abstractProductWishLists;

            this.eventBus.publish(EWishListEvent.ARTICLE_ADDED_TO_WISH_LIST, { imageElement });

            this.publishProductsWishList();
        });
    }

    private removeProduct(payload: IProductWishListEventRemoveProductPayload) {
        const { type, sku } = payload;
        const removeProductServiceFunction = getRemoveProductServiceFunctionByType(type, sku);

        removeProductServiceFunction.then(response => {
            this.concreteProductWishLists = response.concreteProductWishLists;
            this.abstractProductWishLists = response.abstractProductWishLists;

            this.middlewareEventBus.publish<IToastPayload>({
                type: ToastEvent.TOAST_FADE_IN,
                payload: {
                    message: i18next.t('Removed from wish list.', { ns: 'article-list' }),
                },
            });

            this.publishProductsWishList();
        });
    }

    private publishProductsWishList() {
        this.middlewareEventBus.publish<IProductWishListEventPublishAllProductPayload>({
            type: ProductWishListEvent.PUBLISH_ALL_PRODUCT,
            payload: {
                concreteProductWishLists: this.concreteProductWishLists,
                abstractProductWishLists: this.abstractProductWishLists
            },
        });
    }
}

export default class ProductWishListHandler {
    public static init = () => {
        new ProductWishList(
            MiddlewareEventBus.getInstance(),
            EventBus.getInstance(),
        );
    };

    private static wishListContainerElementRoot;
    private static rootCreated: boolean; //to differentiate first call and first root creation to avoid createRoot console warnings

    public static renderIcons = (
        elementScope: HTMLElement,
        imageLocator?: (iconElement: HTMLElement) => HTMLElement,
        requireLoopForRootCreation?: boolean
    ) => {
        const productWishListContainerElements: NodeListOf<HTMLElement> = elementScope
            .querySelectorAll(PRODUCT_WISH_LIST_CONTAINER);

        if (!productWishListContainerElements.length) {
            return;
        }

        productWishListContainerElements.forEach((wishListContainerElement) => {
            const sku = wishListContainerElement.dataset.sku;
            const type = wishListContainerElement.dataset.type as EProductType;

            if (!sku || !type) {
                return;
            }

            if (!this.rootCreated || requireLoopForRootCreation) {
                this.wishListContainerElementRoot = createRoot(wishListContainerElement);
                this.rootCreated = true;
            }

            const etrackerProduct = typeof wishListContainerElement.dataset.etrackerProduct === 'string'
                ? JSON.parse(wishListContainerElement.dataset.etrackerProduct)
                : wishListContainerElement.dataset.etrackerProduct;

            this.wishListContainerElementRoot.render(
                <ProductWishListIcon
                    key={type + sku}
                    sku={sku}
                    type={type}
                    imageLocator={imageLocator}
                    etrackerProduct={etrackerProduct}
                />
            );
        });
    };
}
