import BrandFilterHandler from '../includes/filter-state/BrandFilterHandler';
import SaleFilterHandler from '../includes/filter-state/SaleFilterHandler';
import SizeFilterHandler from '../includes/filter-state/SizeFilterHandler';
import NewlyArrivedFilterHandler from '../includes/filter-state/NewlyArrivedFilterHandler';
import GenderFilterHandler from '../includes/filter-state/GenderFilterHandler';
import ColorFilterHandler from '../includes/filter-state/ColorFilterHandler';
import PriceFilterHandler from '../includes/filter-state/PriceFilterHandler';
import MyBikeFilterHandler from '../includes/filter-state/MyBikeFilterHandler';

import BrandFilter from '../components/sidebar/filters/brand-filter/BrandFilter';
import GenderFilter from '../components/sidebar/filters/gender-filter/GenderFilter';
import PriceFilter from '../components/sidebar/filters/price-filter/PriceFilter';
import ColorFilter from '../components/sidebar/filters/color-filter/ColorFilter';
import SaleFilter from '../components/sidebar/filters/sale-filter/SaleFilter';
import SizeFilter from '../components/sidebar/filters/size-filter/SizeFilter';
import NewlyArrivedFilter from '../components/sidebar/filters/newly-arrived-filter/NewlyArrivedFilter';
import CategoryFilterHandler from '../includes/filter-state/CategoryFilterHandler';
import {
    FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS, PARAM_BACK_KEY,
    VIEW_MODE_GRID,
    VIEW_MODE_LIST
} from '../../shop-ui/components/search-page/constants';
import { DEFAULT_CURRENCY_FACTOR } from 'PyzShopUi/scripts/shop-ui/constants';
import { MaterialFilterHandler } from '../includes/filter-state/MaterialFilterHandler';
import MaterialFilter from '../components/sidebar/filters/material-filter/MaterialFilter';
import { FasteningFilterHandler } from '../includes/filter-state/FasteningFilterHandler';
import FasteningFilter from '../components/sidebar/filters/fastening-filter/FasteningFilter';
import { FeatureFilterHandler } from '../includes/filter-state/FeatureFilterHandler';
import FeatureFilter from '../components/sidebar/filters/feature-filter/FeatureFilter';
import { ISearchPageActionState } from '../../shop-ui/components/search-page/interfaces';
import OutletFilterHandler from 'PyzShopUi/scripts/catalog-page/includes/filter-state/OutletFilterHandler';
import OutletFilter from 'PyzShopUi/scripts/catalog-page/components/sidebar/filters/outlet-filter/OutletFilter';

export const API_URL = '/ajax/catalog';

export enum FilterNames {
    CATEGORY = 'categoryKey',
    BRAND = 'brandName',
    SALE = 'isSale',
    NEWLY_ARRIVED = 'isNew',
    SIZE = 'size',
    GENDER = 'gender',
    COLOR = 'colorName',
    PRICE = 'price',
    MYBIKE = 'bikeCode',
    MATERIAL = 'material',
    FASTENING = 'fastening',
    FEATURE = 'feature',
    OUTLET = 'isOutlet',
}

export enum GenderFilterValues {
    MEN = 'H',
    WOMEN = 'D',
    CHILDREN = 'K',
    UNISEX = 'U',
}

export const MY_BIKE_OPTION_UNIVERSAL = 'universal'

export const FILTER_LABEL_MAPPING = {
    [FilterNames.SALE]: 'Sale %',
    [FilterNames.NEWLY_ARRIVED]: 'New!',
    [FilterNames.GENDER]: {
        [GenderFilterValues.MEN]: 'Mens',
        [GenderFilterValues.WOMEN]: 'Womens',
        [GenderFilterValues.CHILDREN]: 'Children',
        [GenderFilterValues.UNISEX]: 'Include unisex'
    },
    [FilterNames.OUTLET]: 'Outlet',
};

export const FILTER_TYPE_MAPPING = {
    [FilterNames.CATEGORY]: {
        handler: new CategoryFilterHandler()
    },
    [FilterNames.MYBIKE]: {
        handler: new MyBikeFilterHandler()
    },
    [FilterNames.BRAND]: {
        handler: new BrandFilterHandler(),
        filterComponent: BrandFilter,
    },
    [FilterNames.SIZE]: {
        handler: new SizeFilterHandler(),
        filterComponent: SizeFilter,
    },
    [FilterNames.SALE]: {
        handler: new SaleFilterHandler(),
        filterComponent: SaleFilter,
    },
    [FilterNames.NEWLY_ARRIVED]: {
        handler: new NewlyArrivedFilterHandler(),
        filterComponent: NewlyArrivedFilter,
    },
    [FilterNames.GENDER]: {
        handler: new GenderFilterHandler(),
        filterComponent: GenderFilter,
    },
    [FilterNames.COLOR]: {
        handler: new ColorFilterHandler(),
        filterComponent: ColorFilter,
    },
    [FilterNames.PRICE]: {
        handler: new PriceFilterHandler(),
        filterComponent: PriceFilter,
    },
    [FilterNames.MATERIAL]: {
        handler: new MaterialFilterHandler(),
        filterComponent: MaterialFilter,
    },
    [FilterNames.FASTENING]: {
        handler: new FasteningFilterHandler(),
        filterComponent: FasteningFilter,
    },
    [FilterNames.FEATURE]: {
        handler: new FeatureFilterHandler(),
        filterComponent: FeatureFilter,
    },
    [FilterNames.OUTLET]: {
        handler: new OutletFilterHandler(),
        filterComponent: OutletFilter,
    },
};

export enum CatalogContextName {
    SALES = 'sales',
    BIKE_DETAIL = 'bikeDetail',
    SEARCH = 'search',
    GIFT_VOUCHER = 'giftVoucher',
    BRAND = 'brand',
    PROMOTION = 'promotion',
    RECOMMENDATION_SLIDER = 'recommendationSlider',
    COMPATIBLE_PRODUCT_SLIDER = 'compatibleProductSlider',
    MAIN_RUBRIC_SLIDER = 'mainRubricSlider',
    PROMOTION_SLIDER = 'promotionProductSlider',
    RECENTLY_VIEWED_PRODUCT = 'recentlyViewedProduct'
}

export const VIEW_MODE_OPTIONS = [VIEW_MODE_GRID, VIEW_MODE_LIST];

export const DEFAULT_SORT_VALUE = 'name_asc';

export const DEFAULT_ITEM_PER_PAGE_VALUE = '48';

export const BATTERY_DEPOSIT_PRICE_VALUE = 7.5;

export const emptyCatalogPageState: ISearchPageActionState = {
    sort: {
        sortParamNames: [],
        currentSortParam: '',
    },
    pagination: {
        numFound: 0,
        currentPage: 1,
        maxPage: 1,
        currentItemsPerPage: 0,
        config: {
            defaultItemsPerPage: 48,
            validItemsPerPageOptions: [],
        },
    },
    searchResultItems: [],
    filters: [],
    storeConfig: {
        currency: '',
        currencyFactor: DEFAULT_CURRENCY_FACTOR,
        priceMode: 'GROSS_MODE',
    },
    extraInformation: {
        breadcrumb: null,
    },
    isPageLoading: false,
    apiQueryData: {},
    updatePageDebounceTime: FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    categoriesTree: [],
    navigationActiveCategoryKey: null,
    navigationActiveUrl: null,
    skipUpdateBaseOnApiQueryParams: false,
    initCategoryKey: null,
    activeCategoryName: '',
    headTitle: '',
    [PARAM_BACK_KEY]: null,
};
