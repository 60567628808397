import * as React from 'react';
import Tooltip from 'PyzShopUi/scripts/react/common/components/Tooltip';
import ProductBasicPrice from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductBasicPrice';
import ProductPrice from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductPrice';
import { IStoreConfig } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { IProductItem } from 'PyzShopUi/scripts/shop-ui/interfaces';
import FootnoteService from 'PyzShopUi/scripts/shop-ui/services/FootnoteService';
import { EFootnoteNamespace } from 'PyzShopUi/scripts/shop-ui/constants';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import FormattedCurrency from 'PyzShopUi/scripts/react/common/components/FormattedCurrency';
import { BATTERY_DEPOSIT_PRICE_VALUE } from 'PyzShopUi/scripts/catalog-page/constants';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import { FootnoteEvents } from 'PyzShopUi/scripts/shop-ui/events/footnoteEvents';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';

interface IListItemProps {
    product: IProductItem;
    storeConfig: IStoreConfig;
}

const ListItem: React.FunctionComponent<IListItemProps> = props => {
    const { product, storeConfig } = props;
    const { translate } = useTranslationHook();

    const [depositFootnoteNumber, setDepositFootnoteNumber] = React.useState<number>();

    const middlewareEventBusInstance = React.useRef<MiddlewareEventBus>(MiddlewareEventBus.getInstance());
    const footnoteServiceInstance = React.useRef<FootnoteService>(FootnoteService.getInstance());

    const originalPriceOrigin = product.attributes?.originalPriceOrigin;
    const footnoteNumberForPrice = originalPriceOrigin ?
        footnoteServiceInstance
            .current
            .getFootnoteNumber(originalPriceOrigin, EFootnoteNamespace.ARTICLE_PRICE) : '';

    const customerPrice = product.prices.customer ?? null;
    const catalogPrice = product.prices.catalog ?? null;
    const merchantPrice = product.prices.merchant ?? null;
    const hasMultiplePrices = product.hasMultiplePrices ?? false;
    const basicPrice = product.basicPrice ?? null;
    const hasBatteryDeposit = product.extraCost?.batteryDeposit ?? false;

    React.useEffect(() => {
        const subscription = middlewareEventBusInstance.current.subscribe((event: IReactEvent<any>) => {
            if (event.type === FootnoteEvents.FOOTNOTE_UPDATED) {
                if (!hasBatteryDeposit) {
                    return;
                }

                setDepositFootnoteNumber(
                    footnoteServiceInstance
                        .current
                        .getFootnoteNumber('deposit', EFootnoteNamespace.ARTICLE_PRICE)
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    React.useEffect(() => {
        if (!hasBatteryDeposit) {
            return;
        }

        footnoteServiceInstance
            .current
            .addFootnote('deposit', EFootnoteNamespace.ARTICLE_PRICE);

        middlewareEventBusInstance
            .current
            .publish({ type: FootnoteEvents.FOOTNOTE_UPDATED, payload: {} });
    }, [hasBatteryDeposit]);

    const displayExtraCostBatteryDeposit = () => {
        if (!hasBatteryDeposit) {
            return;
        }

        return (
            <span className="product-extra-cost">
                <span>
                    +<FormattedCurrency price={BATTERY_DEPOSIT_PRICE_VALUE} currency={storeConfig.currency}/>
                    &nbsp;&nbsp;
                    {translate('Deposit', { ns: 'article-list' })}
                </span>
                <sup>{depositFootnoteNumber}</sup>
            </span>
        );
    };

    const displayBasicPrice = () => {
        if (!basicPrice) {
            return;
        }

        return (
            <ProductBasicPrice
                unitName={basicPrice.unitName}
                unitAmount={basicPrice.unitAmount}
                unitValue={basicPrice.unitValue}
                currency={storeConfig.currency}
            />
        );
    };

    const productLink = () => {
        let href = product.url ? { 'href': product.url } : null;
        return (
            <a className="no-underline" {...href} tabIndex={-1}>
                {product.brandName} {product.abstractName}
                <span className="sub">
                    {product.bikeAssociation}
                </span>
            </a>
        );
    };

    return (
        <tr key={product.abstractSku}>
            <td>
                <div className="product-name paragraph-s">
                    <Tooltip
                        id={`tooltip-product-${product.abstractSku}`}
                        content={<img src={product.images.externalUrlThumbnail} alt={product.abstractName}/>}
                        isHoverEnabled={true}
                        isDisplayInline={false}
                        showInfoIcon={false}
                        showCloseIcon={false}
                    >
                        <strong className="tooltip-text tooltip-icon"
                                data-tooltip={`tooltip-product-${product.abstractSku}`}>
                            {productLink()}
                        </strong>
                    </Tooltip>
                </div>
            </td>
            <td>
                <p className="paragraph-s"> {product.sku} </p>
            </td>
            <td>
                <div className="paragraph-s">
                    {displayExtraCostBatteryDeposit()}
                    <ProductPrice
                        customerPrice={customerPrice}
                        catalogPrice={catalogPrice}
                        merchantPrice={merchantPrice}
                        hasMultiplePrices={hasMultiplePrices}
                        currency={storeConfig.currency}
                        footnoteNumber={footnoteNumberForPrice}
                    />
                    {displayBasicPrice()}
                </div>
            </td>
        </tr>
    );
};

export default ListItem;
