import * as React from 'react';
import axios, { AxiosResponse } from "axios";

import Slider from "./Slider";
import Categories from "./Categories";
import { ICategory, IRubricProps, IRubricResponse } from "PyzShopUi/scripts/main-rubric-page/interfaces";
import { IProductItem } from "PyzShopUi/scripts/shop-ui/interfaces";
import {ISearchResultItem, IStoreConfig} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { EAccordionEventHandler } from 'PyzShopUi/scripts/modules/events/accordion.event';
import { ListNameContext } from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductItemListNameContext'
import {ListAccordion} from 'PyzShopUi/scripts/modules/listAccordion';
import EventBus from 'PyzShopUi/scripts/utils/eventBus';
import {getApiQueryDataFromLocation} from 'PyzShopUi/scripts/shop-ui/includes/query-string-helper';
import {CatalogContextName} from 'PyzShopUi/scripts/catalog-page/constants';
import EtrackerTracking from 'PyzShopUi/scripts/etracker/EtrackerTracking';

interface RubricState {
    rubricName: string
    items: IProductItem[]
    categories: ICategory[]
    storeConfig: IStoreConfig
}

const RUBRIC_LIST_NAME_PREFIX = 'Rubric-'

const Rubric: React.FunctionComponent<IRubricProps> = ({ apiUrl, skeletonName }) => {
    const [{categories, rubricName, items, storeConfig}, setState] = React.useState<RubricState>({
        categories: [],
        items: [],
        rubricName: '',
        storeConfig: null
    })

    const listAccordionRef = React.useRef<HTMLDivElement>();
    // this is a flag to determine if the ListAccordion class has been initiliazed
    const listInit = React.useRef<boolean>(false);

    const handleSendProductsToEtracker = (resultItems: ISearchResultItem[], currency: string, apiUrl: URL) => {
        const apiQueryDataFromLocation = getApiQueryDataFromLocation(apiUrl);
        if (!resultItems.length) {
            return;
        }
        const listType = EtrackerTracking.getViewProductListType({
            ...apiQueryDataFromLocation,
            context: CatalogContextName.MAIN_RUBRIC_SLIDER
        })
        const eTrackerProductList = EtrackerTracking.buildEtrackerProductList(resultItems, currency)
        EtrackerTracking.viewProductList(eTrackerProductList, listType)
    };
    React.useEffect(() => {
        axios.get(apiUrl.toString()).then((result: AxiosResponse<IRubricResponse>) => {
            setState((s) => ({
                ...s,
                rubricName: result.data.categoryName,
                items: result.data.catalog.resultItems.slice(0, 2) as IProductItem[],
                categories: result.data.childCategories ?? [],
                storeConfig: result.data.catalog.storeConfig
            }))
            handleSendProductsToEtracker(result.data.catalog.resultItems, result.data.catalog.storeConfig.currency, apiUrl)
        });
    }, []);

    // as soon as the element is rendered, create a ListAccordion to add the accordion
    // functionality for the component
    React.useEffect(() => {
        // if the listAccordionRef isn't connected to the DOM element yet
        // or we have already initiliazed the ListAccordion, we don't do anything here
        if (!listAccordionRef.current || listInit.current) {
            return ;
        }

        new ListAccordion(listAccordionRef.current, EventBus.getInstance(), true);
        listInit.current = true;
    },[listAccordionRef.current, listInit.current]) 

    return (
        <div className="cell list-accordion" data-slidetoggle="true" ref={listAccordionRef}>
            <div className="accordion-header" data-toggleelement="header" data-event-handler={EAccordionEventHandler.LAZY_LOAD}>
                <div className="heading3">
                    {rubricName}
                    <i className="accordion-icon" data-toggleelement="icon"></i>
                </div>
            </div>

            <div className="accordion-content" data-toggleelement="content">
                <ListNameContext.Provider value={`${RUBRIC_LIST_NAME_PREFIX}${rubricName}`}>
                    <Slider items={items} skeletonName={skeletonName} storeConfig={storeConfig} />
                </ListNameContext.Provider>
                <Categories categories={categories} />
            </div>
        </div>
    );
};

export default Rubric;
