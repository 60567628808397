export interface IEtrackerProduct {
    id: string;
    name: string;
    price: string;
    currency?: string;
    category?: string[];
}

export const EtrackerEventNames = {
    INSERT_TO_WATCH_LIST: 'insertToWatchlist',
    REMOVE_FROM_WATCH_LIST: 'removeFromWatchlist',
    INSERT_TO_BASKET: 'insertToBasket',
    REMOVE_FROM_BASKET: 'removeFromBasket',
    VIEW_PRODUCT: 'viewProduct',
    VIEW_PRODUCT_LIST: 'viewProductList',
    ORDER: 'order',
} as const;
