import * as React from 'react';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { IStoreConfig } from '../../../../../shop-ui/components/search-page/interfaces';
import { IProductItem } from '../../../../../shop-ui/interfaces';
import ProductItemsByCategory from './ProductItemsByCategory';
import Utils from 'PyzShopUi/scripts/utils/utils';

interface IProductResultListProps {
    products: IProductItem[];
    storeConfig: IStoreConfig;
}

const ProductResultList: React.FunctionComponent<IProductResultListProps> = props => {
    const { products, storeConfig } = props;
    const { translate } = useTranslationHook();

    const displayProductItemsByCategory = () => {
        const productGroupByCategory = products.reduce((category, product) => {
            category[product.categoryName] = [...category[product.categoryName] || [], product];
            return category;
        }, {});

        return Object.keys(productGroupByCategory)
            .map(category => (
            <ProductItemsByCategory
                key={category}
                title={category}
                products={productGroupByCategory[category]}
                storeConfig={storeConfig}
            />
        ));
    };

    return (
        <div className='product-result-list'>
            <table>
                <thead>
                    <tr>
                        <th>
                            <span className='header'> {translate('Article variants', { ns: 'article-list' })} </span>
                        </th>
                        <th>
                            <span className='header'> {translate('Art. No.', { ns: 'article-detail' })} </span>
                        </th>
                        <th>
                            <span className='header'> {translate('Price', { ns: 'article-list' })} </span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {displayProductItemsByCategory()}
                </tbody>
            </table>
        </div>
    );
};

export default ProductResultList;
