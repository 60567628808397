// @ts-nocheck
import {EtrackerEventNames, IEtrackerProduct} from 'PyzShopUi/scripts/etracker/IEtrackerTracking';
import {IProductItem} from 'PyzShopUi/scripts/shop-ui/interfaces';
import Utils from 'PyzShopUi/scripts/utils/utils';
import {IApiQueryData, ISearchResultItem} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import {
    PARAM_CONTEXT
} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import {CatalogContextName} from 'PyzShopUi/scripts/catalog-page/constants';
import ETrackerHelper from 'PyzShopUi/scripts/etracker/ETrackerHelper';

export default class EtrackerTracking {
    public static insertToWatchlist = (eTrackerProduct: IEtrackerProduct) => {
        eTrackerProduct = etrackerCategoryDataEnricher(eTrackerProduct);

        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.INSERT_TO_WATCH_LIST, eTrackerProduct, 1);
        });
    }

    public static removeFromWatchlist = (eTrackerProduct: IEtrackerProduct) => {
        eTrackerProduct = etrackerCategoryDataEnricher(eTrackerProduct);

        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.REMOVE_FROM_WATCH_LIST, eTrackerProduct, 1);
        });
    }

    public static buildEtrackerProductData = (product: IProductItem, currency: string) => {
        const price = typeof product.prices.customer === 'number' && product.prices.customer > 0
            ? Utils.formatPrice(product.prices.customer)
            : typeof product.prices.merchant === 'number' && product.prices.merchant > 0
                ? Utils.formatPrice(product.prices.merchant)
                : `${product.prices.customer || product.prices.merchant || ''}`;

        return {
            id: product.abstractSku,
            name: product.abstractName,
            price: price,
            currency: currency
        }
    }

    public static insertToBasket = (eTrackerProduct: IEtrackerProduct, quantity: Number) => {
        eTrackerProduct = etrackerCategoryDataEnricher(eTrackerProduct);
        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.INSERT_TO_BASKET,
                eTrackerProduct,
                quantity
            );
        });
    }

    public static removeFromBasket = (eTrackerProduct: IEtrackerProduct) => {
        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.REMOVE_FROM_BASKET,
                eTrackerProduct,
                1
            );
        });
    }

    public static viewProduct = (eTrackerProduct: IEtrackerProduct): void => {
        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.VIEW_PRODUCT, eTrackerProduct);
        });
    }

    public static order = (order: object) => {
        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.ORDER, order);
        });
    };

    public static buildEtrackerProductList = (
        products: ISearchResultItem[],
        currency: string,
        categoryName?: string
    ): IEtrackerProduct[] => {
        return products.map((product: IProductItem) => {
            return {
                ...EtrackerTracking.buildEtrackerProductData(product, currency),
                category: categoryName ? [categoryName] : []
            }
        })
    }

    public static viewProductList = (eTrackerProductList: IEtrackerProduct[], listType: string = 'productcatalog') => {
        const productList = {
            listType: listType,
            products: eTrackerProductList
        }
        window._etrackerOnReady.push(function() {
            etCommerce.sendEvent(EtrackerEventNames.VIEW_PRODUCT_LIST,  productList);
        });
    }

    public static getViewProductListType(defaultQueryObject: IApiQueryData) {
        if (!defaultQueryObject[PARAM_CONTEXT]) {
            return ETrackerHelper.getCategoryType(defaultQueryObject)
        }

        switch (defaultQueryObject[PARAM_CONTEXT]) {
            case CatalogContextName.BRAND:
                return ETrackerHelper.getBrandType();
            case CatalogContextName.SEARCH:
                return ETrackerHelper.getSearchType(defaultQueryObject);
            case CatalogContextName.BIKE_DETAIL:
                return ETrackerHelper.getBikeDetailType(defaultQueryObject);
            case CatalogContextName.PROMOTION:
                return ETrackerHelper.getPromotionType(defaultQueryObject);
            case CatalogContextName.PROMOTION_SLIDER:
                return ETrackerHelper.getPromotionSliderType(defaultQueryObject);
            case CatalogContextName.RECOMMENDATION_SLIDER:
                return ETrackerHelper.getRecommendationSliderType(defaultQueryObject);
            case CatalogContextName.COMPATIBLE_PRODUCT_SLIDER:
                return ETrackerHelper.getCompatibleProductSliderType(defaultQueryObject);
            case CatalogContextName.MAIN_RUBRIC_SLIDER:
                return ETrackerHelper.getMainRubricSliderType(defaultQueryObject);
            default:
                return ETrackerHelper.getDefaultListType();
        }
    }
}

const etrackerCategoryDataEnricher = (eTrackerProduct: IEtrackerProduct) => {
    const categoryElement = document.querySelector('#last-category');
    const categoryName = categoryElement ? categoryElement.getAttribute('data-category-name') : null;

    return {
        ...eTrackerProduct,
        category: categoryName ? [categoryName] : []
    };
}
